import { Link } from 'react-router-dom'
import { ButtonTheme } from '../constants/enum'

interface LinkProps {
    className?: string
    buttonText: string
    buttonTheme: ButtonTheme
    path: string
}

export default function LinkCTAReactRouter(props: LinkProps) {
    const { buttonText, className, buttonTheme, path } = props

    return (
        <Link
            className={`flex h-12 cursor-pointer items-center justify-center rounded-xl text-lg font-bold  focus:outline-none focus-visible:ring-2  ${className}  disabled:cursor-not-allowed
      
      ${
          buttonTheme === ButtonTheme.primary &&
          'bg-primary  text-white ring-primary-dark hover:bg-primary-hover focus:ring-primary-dark active:bg-primary-active'
      }

      ${
          buttonTheme === ButtonTheme.secondary &&
          ' bg-background text-primary ring-2 ring-primary hover:bg-primary-light focus:ring-primary-dark  active:text-primary-active'
      }

      ${
          buttonTheme === ButtonTheme.errorSecondary &&
          'bg-background text-red-700 ring-2 ring-red-700 hover:bg-red-50 focus:ring-red-900 active:text-red-900 active:ring-red-900'
      }

      ${
          buttonTheme === ButtonTheme.errorPrimary &&
          'bg-red-700 text-white ring-2 ring-red-700 hover:bg-red-600 focus:ring-red-900 active:bg-red-900 active:ring-red-900'
      }

      ${
          buttonTheme === ButtonTheme.tertiary &&
          'bg-none text-primary hover:bg-indigo-50 active:text-primary-dark'
      }

        `}
            to={path}
        >
            {buttonText}
        </Link>
    )
}
