import React from 'react'
import Alert from '../helpers/customComponents/Alert'
import { AlertType } from '../helpers/constants/enum'
import { formatFreeTrialDate } from '../helpers/helperFunctions'
import { UserProfile } from '../apiClient'
import { Link } from 'react-router-dom'

export function FreeTrialBanner(props: { user: UserProfile }) {
    const noPaymentMethodMessage = (
        <p>
            Your free trial ends on{' '}
            {props.user?.freeTrial && (
                <strong>{formatFreeTrialDate(props.user?.freeTrial)}</strong>
            )}
            . To continue using Bookmark Llama after this,{' '}
            <Link
                className="cursor-pointer font-bold underline hover:no-underline "
                to="/plan-selector"
            >
                click here
            </Link>{' '}
            to select a paid plan.
        </p>
    )

    const hasPaymentMethodMessage = (
        <p>
            Your free trial ends on{' '}
            {props.user?.freeTrial && (
                <strong>{formatFreeTrialDate(props.user?.freeTrial)}</strong>
            )}
            . Your payment method will be automatically charged after this date.
        </p>
    )

    return (
        <Alert
            showAlert={true}
            alertType={AlertType.success}
            alertText={
                props.user.freeTrialStatus === 'payment-method-attached'
                    ? hasPaymentMethodMessage
                    : noPaymentMethodMessage
            }
        />
    )
}
