export const getBookmarksV2Version = '2.0.8'
export const getBookmarksV216Version = '2.0.16'

export function isVersionLower(version: string, minVersion: string): boolean {
    const splitVersion = version.split('.').map(Number)
    const splitMinVersion = minVersion.split('.').map(Number)

    for (let i = 0; i < splitMinVersion.length; i++) {
        // If the version doesn't have this part (implied zero), or it's lower, return true
        if ((splitVersion[i] ?? 0) < splitMinVersion[i]) {
            return true
        }
        // If it's higher, we can already return false
        if ((splitVersion[i] ?? 0) > splitMinVersion[i]) {
            return false
        }
        // If they are equal, we continue to the next part of the version
    }
    // If all parts were equal up to the length of minVersion, the version is not lower
    return false
}
