import { AlertType } from '../constants/enum'
import {
    CheckCircleIcon,
    ExclamationCircleIcon,
    ExclamationTriangleIcon,
    InformationCircleIcon,
} from '@heroicons/react/24/outline'

interface AlertProps {
    showAlert: boolean
    alertType: AlertType
    className?: string
    alertText: string | JSX.Element
    alertIcon?: JSX.Element
}

export default function Alert(props: AlertProps) {
    const { showAlert, alertType, alertText, className, alertIcon } = props

    return (
        <aside
            role={'alert'}
            className={`${
                alertType === AlertType.success &&
                'border border-green-900 bg-green-50 text-green-900'
            } ${
                alertType === AlertType.failure &&
                'border border-red-900 bg-red-50 text-red-900'
            }
            ${
                alertType === AlertType.info &&
                'border border-blue-900 bg-blue-50 text-blue-900'
            }
            ${
                alertType === AlertType.warning &&
                'border border-yellow-900 bg-yellow-50 text-yellow-900'
            } 
            
          ${showAlert ? 'visible' : 'invisible'}
          peer flex min-h-min w-full select-none items-center rounded p-2 font-semibold mb-5 text-lg ${className}`}
        >
            <div className="h-full w-8">
                {alertType === AlertType.failure && (
                    <ExclamationCircleIcon
                        className="h-10 w-10 text-red-500"
                        aria-hidden="true"
                    />
                )}

                {alertType === AlertType.warning && (
                    <ExclamationTriangleIcon
                        className=" h-10 w-10 text-yellow-500"
                        aria-hidden="true"
                    />
                )}

                {alertType === AlertType.info && (
                    <InformationCircleIcon
                        className="h-10 w-10 text-blue-500"
                        aria-hidden="true"
                    />
                )}

                {alertType === AlertType.success && (
                    <CheckCircleIcon
                        className="h-10 w-10 text-green-500"
                        aria-hidden="true"
                    />
                )}
            </div>
            <div className="flex px-4 justify-center ">
                <span className="font-semibold">{alertText}</span>
                {alertIcon && (
                    <div className="w-10 flex justify-center items-center">
                        {alertIcon}
                    </div>
                )}
            </div>
        </aside>
    )
}
