import {
    getBookmarksV216Version,
    isVersionLower,
} from '../../helpers/versionChecks'
import SpinnerSvgIcon from '../../helpers/icons/SpinnerSvgIcon'
import {
    extensionState,
    getSyncEngineStats,
    runSyncEngine,
} from '../../extensionCommunication/messager'
import { useEffect, useState } from 'react'

const SyncEngineRunning = () => {
    return (
        <div className="flex items-center px-2 transition-all ease-in-out duration-200">
            <p className="font-medium mr-2">Checking for updates</p>
            <SpinnerSvgIcon height="22" width="22" className="" />
        </div>
    )
}

const LastRunDisplay = ({ lastRun }: { lastRun: string }) => {
    const [timeText, setTimeText] = useState('')

    useEffect(() => {
        const updateLastRunText = () => {
            const lastRunDate = new Date(lastRun)
            const now = new Date()

            const diffInMs = now.getTime() - lastRunDate.getTime()
            const diffInSeconds = Math.floor(diffInMs / 1000)
            const diffInMinutes = Math.floor(diffInSeconds / 60)

            if (diffInSeconds < 10) {
                setTimeText('Last updated a few seconds ago')
            } else if (diffInSeconds < 60) {
                // Round down to the nearest 10 seconds
                const roundedSeconds = Math.floor(diffInSeconds / 10) * 10
                setTimeText(
                    `Last updated ${roundedSeconds} second${
                        roundedSeconds > 1 ? 's' : ''
                    } ago`
                )
            } else {
                setTimeText(
                    `Last updated ${diffInMinutes} minute${
                        diffInMinutes > 1 ? 's' : ''
                    } ago`
                )
            }
        }

        updateLastRunText()
        const interval = setInterval(updateLastRunText, 1000)

        return () => clearInterval(interval)
    }, [lastRun])

    return (
        <p className="hidden sm:inline-block font-medium mr-2 transition-all duration-100 ease-in-out">
            {timeText}
        </p>
    )
}

export const SyncEngineStatusBar = (props: { state: extensionState }) => {
    const [lastRun, setLastRun] = useState('')
    const [isRunning, setIsRunning] = useState(false)

    const isSupported =
        props.state?.isInstalled &&
        !isVersionLower(props.state.version, getBookmarksV216Version)

    useEffect(() => {
        const interval = setInterval(async () => {
            const stats = await getSyncEngineStats()
            if (stats.lastRun !== lastRun) {
                setLastRun(stats.lastRun)
            }
        }, 5000)

        return () => clearInterval(interval)
    }, [lastRun])

    const delay = (ms: number) =>
        new Promise((resolve) => setTimeout(resolve, ms))

    const runSyncEngineWithLoader = async () => {
        const MINIMUM_DURATION_MS = 1000
        setIsRunning(true)

        const startTime = Date.now()
        await runSyncEngine()
        const elapsedTime = Date.now() - startTime

        const stats = await getSyncEngineStats()

        const remainingTime = MINIMUM_DURATION_MS - elapsedTime
        if (remainingTime > 0) {
            await delay(remainingTime)
        }
        setLastRun(stats.lastRun)
        setIsRunning(false)
    }

    if (!isSupported || !props.state) {
        //Return early if the extension is not installed or the version is lower than the bookmarks v2.15 version
        //In these cases we can't support this functionality
        return <></>
    }

    return isRunning || lastRun === '' ? (
        <SyncEngineRunning />
    ) : (
        <div className="hidden sm:flex items-center px-2  transition-all ease-in-out duration-200">
            <LastRunDisplay lastRun={lastRun} />
            <button
                onClick={async () => {
                    await runSyncEngineWithLoader()
                }}
                className="h-6 w-6"
            >
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth="2.5"
                    stroke="currentColor"
                    className="size-6 stroke-primary"
                >
                    <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0 3.181 3.183a8.25 8.25 0 0 0 13.803-3.7M4.031 9.865a8.25 8.25 0 0 1 13.803-3.7l3.181 3.182m0-4.991v4.99"
                    />
                </svg>
            </button>
        </div>
    )
}
