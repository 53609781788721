/* tslint:disable */
/* eslint-disable */
/**
 * BookmarkLlama
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PostPaymentUrlRequest
 */
export interface PostPaymentUrlRequest {
    /**
     * 
     * @type {string}
     * @memberof PostPaymentUrlRequest
     */
    planName: string;
    /**
     * 
     * @type {string}
     * @memberof PostPaymentUrlRequest
     */
    paymentFrequency: string;
}

/**
 * Check if a given object implements the PostPaymentUrlRequest interface.
 */
export function instanceOfPostPaymentUrlRequest(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "planName" in value;
    isInstance = isInstance && "paymentFrequency" in value;

    return isInstance;
}

export function PostPaymentUrlRequestFromJSON(json: any): PostPaymentUrlRequest {
    return PostPaymentUrlRequestFromJSONTyped(json, false);
}

export function PostPaymentUrlRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): PostPaymentUrlRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'planName': json['plan_name'],
        'paymentFrequency': json['payment_frequency'],
    };
}

export function PostPaymentUrlRequestToJSON(value?: PostPaymentUrlRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'plan_name': value.planName,
        'payment_frequency': value.paymentFrequency,
    };
}

