import { useEffect, useState } from 'react'
import {
    BookmarkNodeV3,
    getBookmarksV3,
} from '../extensionCommunication/messager'

function arraysAreEqual(a: BookmarkNodeV3[], b: BookmarkNodeV3[]): boolean {
    if (a.length !== b.length) return false
    for (let i = 0; i < a.length; i++) {
        if (
            a[i].id !== b[i].id ||
            a[i].name !== b[i].name ||
            a[i].url !== b[i].url
        ) {
            return false
        }

        if (a[i].children.length !== b[i].children.length) return false

        if (!arraysAreEqual(a[i].children, b[i].children)) return false
    }
    return true
}

export function BookmarkSelectTesting() {
    const [bookmarks, setBookmarks] = useState<BookmarkNodeV3[]>([])
    const [lastChangeDetected, setLastChangeDetected] = useState<string | null>(
        null
    )

    const depth = 2

    useEffect(() => {
        getBookmarksV3(depth).then((b) => setBookmarks(b))
    }, [])

    const checkForChanges = async () => {
        const current = await getBookmarksV3(depth)
        if (!arraysAreEqual(current, bookmarks)) {
            setBookmarks(current)
            setLastChangeDetected(new Date().toLocaleString())
        }
    }

    useEffect(() => {
        const intervalId = setInterval(checkForChanges, 5000)
        return () => clearInterval(intervalId)
    }, [bookmarks])

    return (
        <div>
            <h1>Bookmark Debug Page</h1>
            <button onClick={checkForChanges}>Check Changes</button>
            {lastChangeDetected ? (
                <div>Last change detected: {lastChangeDetected}</div>
            ) : (
                <div>No changes detected yet</div>
            )}
        </div>
    )
}
